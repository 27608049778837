
import {defineComponent, onMounted, ref} from "vue";
import Entities from "@/components/base/common/Entities.vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import {buildTerm} from "@/core/helpers/functions";
import ProviderLiveSevice from "@/core/services/ProviderLiveSevice";
import Row from "@/components/base/common/Row.vue";

export default defineComponent({
  name: "Search",
  components: {Row, Entities},
  setup() {
    const filter = ref({npi: '', name: '', city: '', country: '', line1: ''})
    const searching = ref<any>(false);
    const data = ref<any>();
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Provider Search',
        [
          {link: false, router: '', text: 'Provider Search'}
        ]
      )
    })
    const discardEvent = () => {

    }
    const searchEvent = () => {
      if (searching.value) return;
      const terms = buildTerm(filter.value);
      searching.value = true;
      ProviderLiveSevice.fullSearch(terms).then(res => {
        data.value = res;
      }).finally(() => {
        searching.value = false;
      })
    }

    return {
      discardEvent,
      searchEvent,
      filter,
      data,
      searching,
    }
  },


})
